import { PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
import { useDispatch } from "react-redux";
import LogoComponent from "../logoComponent";
import { actionCreators } from "../Redux/AuthRedux";

export interface IRegisterProps {}
export interface IRegisterState {
  login: string;
}

export function Register(props: IRegisterProps) {
  var [login, setLogin] = React.useState<string>("");
  var [email, setEmail] = React.useState<string>("");
  var [password, setPassword] = React.useState<string>("");
  const dispatch = useDispatch();

  return (
    <div>
      <LogoComponent/>
      <TextField
        label="Login"
        value={login}
        onChange={(event, newValue) => setLogin(newValue as string)}
      ></TextField>
      <TextField
        label="Email"
        value={email}
        onChange={(event, newValue) => setEmail(newValue as string)}
      ></TextField>
      <TextField
        label="Hasło"
        type="password"
        canRevealPassword={true}
        value={password}
        onChange={(event, newValue) => setPassword(newValue as string)}
      ></TextField>
      <PrimaryButton onClick={() => registerClick()}>Rejestruj</PrimaryButton>
    </div>
  );
  function registerClick(): void {
  console.log(actionCreators);
    dispatch(actionCreators.register(login, email, password));
  }
}
